<template>
  <div class="era-drug-container">
    <div class="era-drug-title">
      <p>微观纪元 药物筛选</p>
    </div>
    <div class="era-drug-introduce">
      <div class="era-drug-pic">
        <img width="100%" height="100%" src="@/assets/images/drug-01.png" />
      </div>
      <div class="era-drug-text">
        <div class="era-drug-content">药物虚拟筛选是一种用于药物发现的计算技术，通过搜索真实或虚拟的分子库来确定潜在的候选靶点，可以潜在地提高先导化合物的发现，
          是众多制药公司正在探索的新兴策略。<br><br>

          虚拟筛选是使用与潜在生物活性存在某种相关的结构描述来筛选大型分子数据库，找到与这些描述匹配的数据库分子子集，然后可以选择用于后续的生物验证和分析。<br><br>

          虚拟筛选主要有两种策略，分别是基于配体的筛选技术和基于结构的筛选技术，前者是采用活性配体结构的拓扑相互性搜索，后者是采用目标受体的三维特征约束。</div>
      </div>
    </div>
    <div class="era-drug-application">
      <div class="drug-application-text1">工作台应用软件</div>
      <img width="100%" height="100%" src="@/assets/images/drug-03.png" />
      <div class="drug-application-text2">微观纪元新药研发前期服务优势</div>
    </div>
    <div class="era-drug-advantage">
      <div class="adv"><img src="@/assets/images/drug-04.png" />
        <p>将以年计算的筛选周期缩短。</p>
      </div>
      <div class="adv"><img src="@/assets/images/drug-05.png" />
        <p>通过准确计算药物分子与靶蛋白的相互作用，高精度地模拟生物环境。</p>
      </div>
      <div class="adv"><img src="@/assets/images/drug-06.png" />
        <p>对筛选实时监控， 确保科学合理的人员分工，降低人力成本。</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Drug",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.era-drug-container {
  .era-drug-title {
    background: url("../assets/images/drug-02.png") no-repeat center 0px;
    background-position: center 0px;
    background-size: cover;
    width: 100%;
    height: 25vw;

    p {
      color: rgb(255, 255, 255);
      font-family: 思源黑体 CN;
      font-size: 3.8vw;
      font-weight: 580;
      line-height: 120px;
      letter-spacing: 0px;
      padding: 6vw 33.5vw;
    }
  }

  .era-drug-application {
    margin-top: 2vw;

    .drug-application-text1 {
      font-size: 2.2vw;
      font-weight: 500;
      font-family: 思源黑体 CN;
      margin: 0 40vw;
    }

    .drug-application-text2 {
      font-size: 2.2vw;
      font-weight: 500;
      font-family: 思源黑体 CN;
      padding: 0 34vw;
    }
  }

  .era-drug-introduce {
    display: flex;
    margin-top: 2vw;

    .era-drug-pic {
      margin-left: 5vw;
      width: 29vw;
      height: 27vw;
    }

    .era-drug-text {
      margin: 2vw 6vw 0 6vw;
      width: 43%;

      .era-drug-content {
        // border: 5px solid yellow;
        // margin: 2vw 5vw 0;
        font-size: 1.1vw;
        font-weight: 400;
        color: rgb(0, 0, 0);
        line-height: 2.1vw;
      }
    }
  }

  .era-drug-advantage {
    margin-top: 4vw;
    margin-bottom: 10vw;
    display: flex;

    .adv {
      width: 25%;
      height: 25vw;
      margin: 0 auto;
      position: relative;

      img {
        height: 90%;
        width: 100%;
      }

      p {
        font-size: 1.2vw;
        font-weight: 500;
        line-height: 1.8vw;
        margin-top: -2vw;
        text-align: center;
        font-family: 思源黑体 CN;
        width: 100%;
        position: absolute;
        top: 18vw;
        padding: 0px 20px;
      }
    }
  }
}
</style>
